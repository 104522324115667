import React from "react"
import Template from "../../templates/about"

import { useStaticQuery, graphql } from "gatsby"

const Page = props => {
  return <Template {...props} options={options}></Template>
}

const options = {
  title: "Essex Capital Partners Ltd.",
  href: "about/essex-capital-partners",
  id: "mitchell-b-rutter",
  summary: {
    capabilities: ["finance", "asset"],
    text:
      "Founded in 1991 and headquartered in New York City, Essex Capital identifies development opportunities that are positioned to benefit from short- and long-term cultural, demographic and economic trends. The company has proven expertise in market selection, financial analysis, construction, asset management, leasing and disposition. Completed projects include ground-up construction, asset repositioning, public/private partnerships, and large-scale master planning projects.",
  },
  body: [
    {
      text:
        "In the past three decades, Essex Capital has overseen the acquisition, repositioning and development of more than five million square feet of assets with a value of more than $3 billion. Essex Capital’s success is based on creative vision and attention to detail",
    },
    {
      text:
        "The company creates value for partners in all phases of the investment cycle, from financial analysis to disposition. Essex Capital’s flexibility creates a powerful competitive advantage, and the company has earned a reputation for the successful execution of difficult and complex projects. Essex Capital values relationships with investors, institutions and public entities that seek a fully integrated real estate company with deep industry experience, broad capabilities and a creative approach.",
    },
  ],
}

export default props => {
  const data = useStaticQuery(graphql`
    query {
      imageThree: file(relativePath: { eq: "more/berry_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageCube: file(relativePath: { eq: "banners/e_c_p.png" }) {
        childImageSharp {
          fluid(maxWidth: 1180) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageOne: file(relativePath: { eq: "500/close_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      imageTwo: file(relativePath: { eq: "2dag/lobby_address_400.png" }) {
        childImageSharp {
          fluid(maxHeight: 400) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  const imageThree = data.imageThree.childImageSharp.fluid
  const imageOne = data.imageOne.childImageSharp.fluid
  const imageTwo = data.imageTwo.childImageSharp.fluid
  const imageCube = data.imageCube.childImageSharp.fluid
  const images = [imageTwo, imageOne, imageThree, imageCube]
  return <Page {...props} images={images} />
}
